@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --theme-scroll-background: rgba(0, 0, 0, 0.1);
  --theme-scroll-thumb-background: rgba(0, 0, 0, 0.2);
  --swiper-navigation-color: #fff;
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  --swiper-pagination-inactive-color: #545454;
  --swiper-pagination-bullet-color: #545454;
  --swiper-navigation-top-offset: 46%;
  --swiper-navigation-sides-offset: 16px;
  --swiper-pagination-bottom: 128px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  height: 100%;
  padding: 0 !important;
}

body {
  background: #151515;
  height: 100%;
}

input,
textarea {
  spellcheck: false;
}

#__next {
  height: 100%;
  overflow: hidden;
}

.bg-paper {
  background-image: url('/images/natural-paper.png');
}

.Toastify__toast-container {
  padding: 16px;
  border-radius: 8px;
}

.Toastify__toast {
  background-image: url('/images/info_bg.png');
  background-size: 100% 100%;
}

.Toastify__toast-icon {
  width: 40px;
}

.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-image: url('/images/slider_bg.png');
    background-size: cover;
  }

  &::-webkit-scrollbar-thumb {
    background: #85827d;
    border-radius: 7px;
    background-clip: padding-box;
  }

  /* &::-webkit-scrollbar-button:vertical:increment {
    background-image: url('/images/slider_down.png');
    width: 16px;
    height: 16px;
    background-size: contain;
  }

  &::-webkit-scrollbar-button:vertical:decrement {
    background-image: url('/images/slider_up.png');
    width: 16px;
    height: 16px;
    background-size: contain;
  }

  &::-webkit-scrollbar-button:horizontal:increment {
    background-image: url('/images/slider_down.png');
    width: 16px;
    height: 16px;
    background-size: contain;
    transform: rotate(90deg);
  }

  &::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url('/images/slider_up.png');
    width: 16px;
    height: 16px;
    background-size: contain;
    transform: rotate(90deg);
  } */
}

.text-stroke {
  -webkit-text-stroke: 0.5px #85827d;
}

.rainbow-text {
  background: linear-gradient(
    45deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* .swiper-horizontal {
  bottom: 42px;
} */

.swiper-pagination-bullet-active {
}

.swiper-pagination-bullet {
  background: #fff !important;
}

@layer utilities {
  .active-scale-90 {
    transition: transform 0.2s;
    transform: scale(1);
  }
  .active-scale-90:active {
    transform: scale(0.9);
  }
}

/* @layer utilities {
  .hover-scale-1_3 {
    transition: transform 0.1s;
  }
  .hover-scale-1_3:hover {
    transform: scale(1.03);
  }
} */

.input-custom:focus {
  border: none;
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']::-ms-clear {
  display: none;
}

input[type='number']::-ms-reveal,
input[type='number']::-ms-clear {
  display: none;
}

.custom-select {
  width: 100%;
  cursor: pointer;
  background: transparent;
}

@keyframes textGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.span-gradient {
  display: inline-block;
  background: linear-gradient(to right, #d4c197, #8c6d31);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  transition: color 0.3s ease;
}

.span-gradient-hover:hover {
  color: transparent;
  animation: textGradient 2s ease infinite;
}

.character {
  position: absolute;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes growAndRotate {
  0% {
    transform: scale(1.5) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(1440deg); /* 360度 x 4回転 */
  }
}

.grow-and-rotate {
  animation: growAndRotate 1.5s forwards;
  animation-delay: 0.3s;
}

@keyframes bounce {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.bounce {
  animation: bounce 1s forwards;
}

@keyframes shine {
  0% {
    left: -50%;
  }
  100% {
    left: 150%;
    opacity: 0;
  }
}

.correct-card {
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  width: 25%;
  height: 292px;
}

.correct-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 90%;
  height: 92%;
  z-index: 10;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 2s forwards;
  transform: skewX(-45deg);
}

.solid {
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 0, 0.7) 0%,
    transparent 50%,
    transparent 50%,
    rgba(255, 255, 0, 0.4) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 0, 1) 0%,
    transparent 40%,
    transparent 60%,
    rgba(255, 255, 0, 0.7) 100%
  );
  box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.1) inset,
    0 2px 10px 0 rgba(255, 255, 255, 0.2) inset,
    0 -2px 2px 0 rgba(0, 0, 0, 0.1) inset;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 50%;
  height: 8px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.custom-range:hover {
  opacity: 1;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  background: #d4c197;
  border: 2px solid #fef1be;
}

.edge-button:hover {
  opacity: 1;
}

.edge-button:disabled {
  opacity: 0.5;
}

.normal-button:hover {
  opacity: 1;
}

.normal-button:disabled {
  opacity: 0.5;
}

.room-icon {
  opacity: 0.8;
}

.room-icon:hover {
  opacity: 1;
}

.custom-checkbox {
  display: none; /* Hide the default checkbox */
}

.custom-checkbox + label {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: none;
  cursor: pointer;
  border: 1px solid;
  border-radius: 8px;
}

.custom-checkbox:checked + label {
  background: url('/images/info_icon_check.png') no-repeat center;
  width: 40px;
  height: 40px;
}

.custom-checkbox:disabled + label {
  cursor: not-allowed;
}

@keyframes flyUpAndDisappear {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fly-up {
  animation: flyUpAndDisappear 0.2s forwards;
}
